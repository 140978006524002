import { readable } from 'svelte/store';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import type { Asset } from '$generated/asset';

import ASSETS_0 from '$generated/assets/404.webp?w=800&format=avif&imagetools';
import ASSETS_1 from '$generated/assets/404.webp?w=1280&format=avif&imagetools';
import ASSETS_2 from '$generated/assets/404.webp';
import ASSETS_3 from '$generated/assets/404.webp?w=768&h=320&format=avif&imagetools';
import ASSETS_4 from '$generated/assets/avatar.png?w=800&format=avif&imagetools';
import ASSETS_5 from '$generated/assets/avatar.png?w=1280&format=avif&imagetools';
import ASSETS_6 from '$generated/assets/avatar.png';
import ASSETS_7 from '$generated/assets/avatar.png?w=768&h=320&format=avif&imagetools';
import ASSETS_8 from '$generated/assets/backgroundpicture.png?w=800&format=avif&imagetools';
import ASSETS_9 from '$generated/assets/backgroundpicture.png?w=1280&format=avif&imagetools';
import ASSETS_10 from '$generated/assets/backgroundpicture.png';
import ASSETS_11 from '$generated/assets/backgroundpicture.png?w=768&h=320&format=avif&imagetools';
import ASSETS_12 from '$generated/assets/logo-trent.svg?w=800&format=avif&imagetools';
import ASSETS_13 from '$generated/assets/logo-trent.svg?w=1280&format=avif&imagetools';
import ASSETS_14 from '$generated/assets/logo-trent.svg';
import ASSETS_15 from '$generated/assets/logo-trent.svg?w=768&h=320&format=avif&imagetools';
import ASSETS_16 from '$generated/assets/logo.svg?w=800&format=avif&imagetools';
import ASSETS_17 from '$generated/assets/logo.svg?w=1280&format=avif&imagetools';
import ASSETS_18 from '$generated/assets/logo.svg';
import ASSETS_19 from '$generated/assets/logo.svg?w=768&h=320&format=avif&imagetools';

export const assets = (() => {
  const _data = new Map<string, Asset.Image>([
    ['/404.webp', {800:ASSETS_0,1280:ASSETS_1,original:ASSETS_2,banner:ASSETS_3} as Asset.Image],
['/avatar.png', {800:ASSETS_4,1280:ASSETS_5,original:ASSETS_6,banner:ASSETS_7} as Asset.Image],
['/backgroundpicture.png', {800:ASSETS_8,1280:ASSETS_9,original:ASSETS_10,banner:ASSETS_11} as Asset.Image],
['/logo-trent.svg', {800:ASSETS_12,1280:ASSETS_13,original:ASSETS_14,banner:ASSETS_15} as Asset.Image],
['/logo.svg', {800:ASSETS_16,1280:ASSETS_17,original:ASSETS_18,banner:ASSETS_19} as Asset.Image]
  ]);

  const { subscribe } = readable<Map<string, Asset.Image>>(_data);

  return {
    subscribe,
  };
})();
